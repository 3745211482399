import React, { useEffect } from "react";
import PropTypes from "prop-types";
import GlobalStyles from "../components/atoms/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/styling";
import { useWindowSize } from "react-use";
import Nav from "../components/atoms/Nav";

function Index(props) {
  const size = useWindowSize();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--windowHeight",
      `${size.height}px`
    );
  }, [size]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Nav text={props.location.pathname !== '/'}/>
        <main>{props.children}</main>
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;


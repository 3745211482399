import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Logo from "../../assets/svg/logo.inline.svg";
import PropTypes from "prop-types";

const Holder = styled.nav`
  position: sticky;
  padding: 1rem 2rem;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  a {
    pointer-events: all;
  }
  svg {
    width: 3rem;
    height: auto;
    path {
      fill: var(--white);
      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(11) {
        fill: var(--green);
      }
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(9) {
        fill: var(--red);
      }
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(10) {
        fill: var(--pink);
      }
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(12) {
        fill: var(--yellow);
      }
    }
  }
`;

const CaseHeader = styled.div`
  max-width: 15rem;
  background-color: var(--black);
  padding: 0.5rem;
  border-radius: 0.5rem;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  p {
    margin: 0;
    ${(props) => props.theme.fluidType(-1)};
  }
`;

const Nav = (props) => {
  return (
    <Holder>
      <Link to="/">
        <Logo />
      </Link>
      <CaseHeader show={props.text}>
        <p>
          A design & development studio creating extended reality and
          browser-based 3D products and services.
        </p>
      </CaseHeader>
    </Holder>
  );
};

Nav.propTypes = {
  text: PropTypes.bool.isRequired,
};

export default Nav;

